import React from "react";

function Card() {
  return (
    <div className="col-3 card mx-2 my-2 my-lg-0  border border-0" style={{ width: "20rem" }}>
      <img src="https://th.bing.com/th/id/OIP.nWMQn5fp5zoDwMCjRjHb6gHaE8?pid=ImgDet&rs=1" className="card-img-top img-fluid" alt="..." />
      <div className="card-body">
        <p className="card-text">
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </p>
      </div>
    </div>
  );
}

export default Card;
