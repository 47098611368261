import React from 'react'

function Heading() {
  return (
    <>
    <div className=' d-flex justify-content-around mt-5'>
    <div className='col-12 col-lg-4 ms-2'>
        <p className='text-lg-start text-center'>Lorem ipsum dolor sit amet.</p>
        <h2 className='text-center text-lg-start my-3 mb-lg-0'>Lorem ipsum dolor sit amet.</h2>
    </div>
    <div className='col-12 col-lg-4 text-lg-start text-center'>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias delectus recusandae labore ea eveniet eos quaerat repellendus rerum culpa quos.</p>
    </div>
    </div>
    </>
    
    
  )
}

export default Heading
