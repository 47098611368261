import React from 'react'
import Card from '../Card/Card'

function CardContainer() {
  return (
    <div className='row d-flex my-5 justify-content-center'>
    <Card/>
    <Card/>
    <Card/>
    </div>
  )
}

export default CardContainer
