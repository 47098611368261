
import './App.css';
import Navbar from './components/Navbar/Navbar';
import Caraousal from './components/Caraousal/Caraousal';
import CardContainer from './components/CardContainer/CardContainer';
import Heading from './components/CardHeading/Heading';
import About from './components/About/About';
import Footer from './components/Footer/Footer';

function App() {
  return (
  <>
  <Navbar/>
  <Caraousal/>
  <div className="container">
  <Heading/>
 <CardContainer/>
  </div>
  <About/>
  <Footer/>
  </>
  );
}

export default App;
