import React from 'react'
import styles from './Navbar.module.css'
function Navbar() {
  return (
    <div>
    <nav className={`navbar ${styles.theme} text-light navbar-expand-lg `}>
    <div className="container-fluid">
  <a className="navbar-brand fs-4 fw-4 text-light" href="#">
    DreamDoor
  </a>
  <button
    className="navbar-toggler"
    type="button"
    data-bs-toggle="collapse"
    data-bs-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span className="navbar-toggler-icon" />
  </button>
  <div className="collapse navbar-collapse" id="navbarSupportedContent">
    <ul className="navbar-nav ms-auto me-3 mb-2 mb-lg-0">
      <li className="nav-item">
        <a className={`nav-link  ${styles.navLinks}`} aria-current="page" href="/">
          Home
        </a>
      </li>
      <li className={`nav-item`}>
        <a className={`nav-link ${styles.navLinks}`} href="/">
          Contact
        </a>
      </li>
      <li className={`nav-item `}>
        <a className={`nav-link ${styles.navLinks}`} href="/">
          Our Services
        </a>
      </li>
      <li className={`nav-item`}>
        <a className={`nav-link ${styles.navLinks}`} href="#about">
          About
        </a>
      </li>
      
      
    </ul>
    
  </div>
</div>

    </nav>
  </div>
  )
}

export default Navbar
