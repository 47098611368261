import React from 'react'
import styles from './About.module.css'

function About() {
  return (
    <div id='about' className={`${styles.about} `}>
      
    </div>
  )
}

export default About
